import React, { useState, useEffect, useRef } from 'react';
import { ImPlay2 } from 'react-icons/im';
import { triggerCustomGTM } from '../../utils/helper';

function CustomVideo({
  videoId,
  videoThumbnail,
  videoIdParam,
  dontAllowPlay,
  onPlay,
  insideSlider,
  altText,
  customTagText,
  videoName   
}) {
  const [startVideo, setStartVideo] = useState(false);
  const playerRef = useRef();
  const imgRef = useRef();

  // Load Vimeo SDK
  useEffect(() => {
    const existingScript = document.getElementById('vimeo-sdk');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://player.vimeo.com/api/player.js';
      script.id = 'vimeo-sdk'; // give it an id
      document.body.appendChild(script);
    }
  }, []);

  // Preload video metadata
  useEffect(() => {
    let vimeoPlayer;
  
    if (startVideo && window.Vimeo && playerRef.current) {
      vimeoPlayer = new window.Vimeo.Player(playerRef.current, { url: `https://vimeo.com/${videoId}?h=${videoIdParam}` });
  
      vimeoPlayer.on('loaded', function() {
        if (playerRef.current) {
          playerRef.current.src += '&autoplay=1'; 
        }
      });
    }
  
    return () => {
      if (vimeoPlayer) {
        vimeoPlayer.unload();
      }
    };
  }, [startVideo, videoId, videoIdParam]);

  // Lazy load handler
  useEffect(() => {
    let observer;

    if (startVideo && playerRef.current) {
      observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          playerRef.current.src += '&autoplay=1';
          observer.unobserve(playerRef.current);
        }
      });

      observer.observe(playerRef.current);
    }

    return () => observer && observer.disconnect();
  }, [startVideo]);

  // Lazy load thumbnail
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, obs) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const img = entry.target;
            const src = img.getAttribute('data-src');

            img.setAttribute('src', src);
            img.classList.add('fade');

            obs.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    imgRef.current && observer.observe(imgRef.current);

    return () => observer.disconnect();
  }, []);

  const handleStartClick = (e) => {
    e.preventDefault();

    if (!dontAllowPlay) {
      !insideSlider && setStartVideo(true);
      onPlay && onPlay();
    }

    if (!startVideo) {
      e.currentTarget.setAttribute('tabindex', '0'); 

      // Create the iframe when the play button is clicked
      const iframe = document.createElement('iframe');
      iframe.src = `https://player.vimeo.com/video/${videoId}?h=${videoIdParam}`;
      iframe.width = '100%';
      iframe.height = '100%';
      iframe.frameBorder = '0';
      iframe.allowFullScreen = true;
      iframe.allow = 'autoplay';
      playerRef.current.appendChild(iframe);
    }

    if (customTagText) {
      triggerCustomGTM({
        event: "gtm.click",
        customTag: customTagText,
        videoName: videoName
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') handleStartClick(e);
  };

  return (
    <div className="card-video bg-cover main-video">
      <img 
        ref={imgRef}
        data-src={videoThumbnail}
        alt={altText}
        style={{display: !startVideo ? 'block' : 'none'}} 
      />

      <div
        ref={playerRef}
        style={{display: startVideo ? 'inline' : 'none'}}
      />

      <button
        tabIndex={startVideo ? '0' : '-1'}
        style={{display: !startVideo ? 'block' : 'none'}}
        className="play-btn"
        onClick={handleStartClick}
        onKeyDown={handleKeyDown} 
        aria-label={`Play ${videoName}`} // Use dynamic label for better accessibility
      >
        <ImPlay2 />
      </button>
    </div>
  );
}


export default CustomVideo;